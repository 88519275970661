const originalFetch = fetch;

function initInterceptor(): void {
  window.fetch = async (url, requestInit) => {
    const token = localStorage.getItem("accessToken");
    const requestHeaders:
      | Record<string, string>
      | undefined = requestInit?.headers as Record<string, string>;

    const getToken = (): string => {
      if (requestHeaders && requestHeaders.Authorization) {
        return `${requestHeaders.Authorization}`;
      }

      if (token) {
        return `Bearer ${token}`;
      }

      return "";
    };

    const headers = new Headers({
      Accept: "application/json",
      "Content-type": "application/json",
    });

    const authToken = getToken();

    if (authToken) {
      headers.set("Authorization", authToken);
    }

    if (requestHeaders) {
      Object.keys(requestHeaders).forEach((key) => {
        headers.set(key, requestHeaders[key]);
      });
    }

    return new Promise((resolve, reject) => {
      originalFetch(url, {
        ...requestInit,
        headers,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
}
export default initInterceptor;
