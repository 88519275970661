import { Modal } from 'shared/components/inputModal/modal';
import React, { Dispatch } from 'react';


type CreateCourseModalProps = {
  setShowDeleteConfirmModal: Dispatch<React.SetStateAction<boolean>>;
  deleteCourseGroup: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
};

export const DeleteCourseModal = ({
  setShowDeleteConfirmModal, deleteCourseGroup,
}: CreateCourseModalProps) => (
  <Modal>
    <h1>Weet je zeker dat je deze cursus wilt verwijderen?</h1>
    <button className="modal_button_secondary" type="button" onClick={() => setShowDeleteConfirmModal(false)}>Nee</button>
    <button className="modal_button_primary" type="button" onClick={deleteCourseGroup}>Ja</button>
  </Modal>
);
