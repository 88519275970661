import React from 'react';
import GridContainer from 'mui-pro/components/Grid/GridContainer';
import GridItem from 'mui-pro/components/Grid/GridItem';
import Card from 'mui-pro/components/Card/Card';
import CardBody from 'mui-pro/components/Card/CardBody';
import ReactTable from 'mui-pro/components/ReactTable/ReactTable';

export type CourseGroupTableItem = {
  id: number;
  urlId?: string | undefined;
  recordName: string | null;
  actions: JSX.Element | null;
};

type DatagridProps = {
  dataTable: CourseGroupTableItem[];
  urlPath?: string | undefined;
};

export const Datagrid = ({
  dataTable, urlPath,
}: DatagridProps) => (
  <>
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: 'Zoeken',
                  accessor: 'recordName',
                },
                {
                  Header: '',
                  accessor: 'actions',
                },
              ]}
              data={dataTable}
              urlPath={urlPath}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  </>
);
