import React, {
  useState,
} from 'react';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import { makeStyles } from '@material-ui/core/styles';

import AdminNavbar from 'mui-pro/components/Navbars/AdminNavbar';
import Sidebar from 'mui-pro/components/Sidebar/Sidebar';
import styles from 'mui-pro/assets/jss/material-dashboard-pro-react/layouts/adminStyle';
import { RouteType, dashRoutes, Routes } from '../../livis-backoffice-app/routes';

const useStyles = makeStyles(styles as any);

export default function Dashboard({ ...props }) {
  const { ...rest } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [color] = useState('blue');
  const [bgColor] = useState('black');
  const [logo] = useState(require('mui-pro/assets/img/livis-logo.svg'));
  const classes = useStyles();
  const [dashboardRoutes] = useState(dashRoutes);

  const getActiveRoute = (routes: RouteType[]): any => {
    const activeRoute = 'LIVIS';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        const collapseActiveRoute = getActiveRoute(routes[i].views ?? []);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      }
    }

    return activeRoute;
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div>
      <Sidebar
        miniActive={false}
        rtlActive={false}
        routes={dashboardRoutes}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        children={null}
        key={'Sidebar'}
        {...rest}
      />
      <div className={classes.mainPanel}>
        <AdminNavbar
          brandText={getActiveRoute(dashboardRoutes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Routes />
          </div>
        </div>
      </div>
    </div>
  );
}
