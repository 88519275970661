import { AppState, useAppState } from 'auth/appState';
import React, { FunctionComponent } from 'react';
import { Redirect, Route } from 'react-router-dom';

type AuthenticatedRouteProps = {
  component: FunctionComponent;
  path: string;
};

const selector = (state: AppState) => state.isAuthenticated;

export const AuthenticatedRoute = ({
  component: Component,
  path,
}: AuthenticatedRouteProps) => {
  const isAuthed = useAppState(selector).current;
  return (
        <Route path={path}>
            {isAuthed ? (
                <Component />
            ) : (
                    <Redirect
                        to={{
                          pathname: '/login',
                          state: { prevPath: window.location.pathname },
                        }}
                    />
            )}
        </Route>
  );
};
