import { Close, Edit } from '@material-ui/icons';
import { LmsResultModel } from 'apiServices/controllers/lms';
import React from 'react';
import classes from '../lms-overview.module.scss';

interface LmsOverviewActionsProps {
  item: LmsResultModel;
  key: number;
  onEdit: (item: LmsResultModel, key: number) => void;
  onDelete: (item: LmsResultModel, key: number) => void;
}

export const LmsOverviewActions = ({
  onEdit, onDelete, item, key,
}: LmsOverviewActionsProps) => (
  <div style={{ display: 'flex', marginLeft: '1em' }} className="actions-right">
    <button
      className={classes.button}
      type='button'
      onClick={(e) => {
        onEdit(item, key);
        e.preventDefault();
      }}>
      <Edit />
    </button>
    <button
      className={classes.button_left}
      type='button'
      onClick={(e) => {
        onDelete(item, key);
        e.preventDefault();
      }}>
      <Close />
    </button>
  </div>
);
