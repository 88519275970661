// all single user related calls

import { http } from "../http";

export class AuthController {
  constructor() {
    throw Error(
      "Authcontroller should not initialize, try using it like this => authcontroller.login"
    );
  }

  static getNewAccessToken = (token: string) =>
    http<{ accessToken: { token: string }; refreshToken: { token: string } }>(
      `${process.env.REACT_APP_API}connect/token`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

  static login = (email: string, password: string) =>
    http<{ accessToken: { token: string }; refreshToken: { token: string } }>(`${process.env.REACT_APP_API}connect/authorize`, {
      method: "POST",
      headers: new Headers(
        { 'content-type': 'application/json' },
      ),
      body: JSON.stringify({
        email,
        password,
      }),
    });

  static reqPasswordReset = (email: string, template: number) =>
    fetch(`${process.env.REACT_APP_API}identity/reqPasswordReset`, {
      method: "POST",
      headers: new Headers(
        { 'content-type': 'application/json' },
      ),
      body: JSON.stringify({
        email,
        template,
      }),
    })
      .then((res) => (res.status === 200 ? res.json() : undefined))
      .catch((err) => `something went wrong error: ${err}`);

  static changePassword = (password: string) =>
    fetch(`${process.env.REACT_APP_API}identity/updatepassword`, {
      method: "POST",
      headers: new Headers(
        { 'content-type': 'application/json' },
      ),
      body: JSON.stringify({
        password,
      }),
    })
      .then((res) => (res.status === 200 ? res.json() : undefined))
      .catch((err) => `something went wrong error: ${err}`);
}
