import React, { useEffect, useState } from 'react';
import { ApiController } from 'apiServices/apiController';
import { CourseGroupListModel } from 'apiServices/controllers/courseGroup';
import { CourseGroupsOverview } from './course-groups-overview';

type CourseGroupsAjaxFetch = {
  isFetched: boolean;
  loading: boolean;
  error: boolean;
  data: CourseGroupListModel | null;
};

export const CourseGroupsAjax = () => {
  const [{
    isFetched, loading, error, data,
  }, setCourseGroupsAjaxFetch] = useState<CourseGroupsAjaxFetch>({
    isFetched: false,
    loading: true,
    error: false,
    data: null,
  });

  useEffect(() => {
    if (!isFetched) {
      setCourseGroupsAjaxFetch((prev) => ({ ...prev, isFetched: true }));

      try {
        const fetchCourseGroupsList = async () => {
          const response = await ApiController.CourseGroup.getCourseGroupList();
          return setCourseGroupsAjaxFetch({
            isFetched: true,
            loading: false,
            error: false,
            data: response,
          });
        };

        fetchCourseGroupsList();
      } catch (error) {
        setCourseGroupsAjaxFetch({ isFetched: true, loading: false, error: true, data: null });
      }
    }
  }, [isFetched]);

  if (loading || !data) {
    return <>Laden...</>;
  }

  if (error) {
    return <>Kon cursus groepen niet ophalen</>;
  }

  return <CourseGroupsOverview dataObject={data} />;
};
