import React, { ChangeEvent, FormEvent } from 'react';
import { Modal } from 'shared/components/inputModal/modal';


type CreateCourseModalProps = {
  editCourseGroup: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  version?: string;
  entryPoint?: string;
  scormVersion?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setShowEditCourseModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditCourseModal = ({
  editCourseGroup, version, entryPoint, scormVersion, onChange, setShowEditCourseModal,
}: CreateCourseModalProps) => (
  <Modal>
    <h1>Cursus aanpassen</h1>
    <form onSubmit={editCourseGroup}>
      <input className="input" placeholder="Nieuwe cursus groep naam" type="text" name="newVersion" value={version} onChange={(e) => onChange(e)} />
      <input className="input" placeholder="Nieuwe entry point" type="text" name="newEntryPoint" value={entryPoint} onChange={(e) => onChange(e)} />
      <input className="input" placeholder="Nieuwe scorm version" type="text" name="newScormVersion" value={scormVersion} onChange={(e) => onChange(e)} />
      <div className="button_div">
        <button className="modal_button_secondary" type="button" onClick={() => setShowEditCourseModal(false)}>Annuleren</button>
        <button className="modal_button_primary" type="submit">Verstuur</button>
      </div>
    </form>
  </Modal>
);
