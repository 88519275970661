import { LmsCourseResultModel } from 'apiServices/controllers/course';
import { http } from '../http';

export type LmsListModel = {
  lmses: LmsResultModel[] | null;
  isValid: boolean;
  message: string | null;
};

export type LmsResultModel = {
  id: string;
  name: string | null;
  lmsCourses: LmsCourseResultModel[] | null;
  isValid: boolean;
  message: string | null;
};

export type LmsTokenResultModel = {
  token: string | null;
  isValid: boolean;
  message: string | null;
};

export class LmsController {
  constructor() {
    throw Error(
      'CourseController should not initialize, try using it like this => CourseController.login',
    );
  }

  static getLmsList = async (): Promise<LmsListModel> => http<LmsListModel>(`${process.env.REACT_APP_SERVER_URL}lms/list`);

  static getLmsById = async (lmsId: string): Promise<LmsResultModel> => http<LmsResultModel>(`${process.env.REACT_APP_SERVER_URL}lms/${lmsId}`);

  static getCoursesByLmsId = async (lmsId: string): Promise<LmsResultModel> => http<LmsResultModel>(`${process.env.REACT_APP_SERVER_URL}lms/${lmsId}`);

  static createLms = async (name: string) => http<Promise<LmsResultModel>>(`${process.env.REACT_APP_SERVER_URL}lms/create`, {
    method: 'POST',
    headers: new Headers(
      { 'content-type': 'application/json' },
    ),
    body: JSON.stringify({
      name,
    }),
  });

  static EditLms = async (id: string, name: string) => http<Promise<void | string>>(`${process.env.REACT_APP_SERVER_URL}lms/update`, {
    method: 'PUT',
    headers: new Headers(
      { 'content-type': 'application/json' },
    ),
    body: JSON.stringify({
      id,
      name,
    }),
  });

  static coupleLmsToCourses = async (lmsId: string, courseIds?: string[]) => http<Promise<void | string>>(`${process.env.REACT_APP_SERVER_URL}lms/coupletocourses`, {
    method: 'PUT',
    headers: new Headers(
      { 'content-type': 'application/json' },
    ),
    body: JSON.stringify({
      lmsId,
      courseIds,
    }),
  });

  static getTokenByLmsId = async (lmsId: string): Promise<LmsTokenResultModel> => http<LmsTokenResultModel>(`${process.env.REACT_APP_SERVER_URL}lms/generatetoken/${lmsId}`);

  static deleteLms = async (lmsId: string) => http<Promise<void | string>>(`${process.env.REACT_APP_SERVER_URL}lms/delete/${lmsId}`, {
    method: 'DELETE',
  });
}
