import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CoursesByCourseGroupModel } from 'apiServices/controllers/courseGroup';
import { ApiController } from 'apiServices/apiController';
import { CourseGroupOverview } from './course-group-overview';

type CourseGroupAjaxFetch = {
  isFetched: boolean;
  loading: boolean;
  error: boolean;
  data: CoursesByCourseGroupModel | null;
};

export const CourseGroupAjax = () => {
  const { id }: any = useParams();
  const [{
    isFetched, loading, error, data,
  }, setCourseGroupAjaxFetch] = useState<CourseGroupAjaxFetch>({
    isFetched: false,
    loading: true,
    error: false,
    data: null,
  });

  useEffect(() => {
    if (!isFetched) {
      setCourseGroupAjaxFetch((prev) => ({ ...prev, isFetched: true }));

      try {
        const fetchCourseGroupsList = async () => {
          const response = await ApiController.CourseGroup.getCoursesByCourseGroup(id);

          return setCourseGroupAjaxFetch({
            isFetched: true,
            loading: false,
            error: false,
            data: response,
          });
        };

        fetchCourseGroupsList();
      } catch (error) {
        setCourseGroupAjaxFetch({
          isFetched: true, loading: false, error: true, data: null,
        });
      }
    }
  }, [isFetched, id]);

  if (loading || !data) {
    return <>Laden...</>;
  }

  if (error) {
    return <>Kon cursus groepen niet ophalen</>;
  }

  return <CourseGroupOverview dataObject={data} />;
};
