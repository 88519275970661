import { Modal } from 'shared/components/inputModal/modal';
import React, { FormEvent } from 'react';


type CreateCourseModalProps = {
  createLms: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  setShowCreateCourseModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateLmsModal = ({
  createLms, onChange, setShowCreateCourseModal,
}: CreateCourseModalProps) => (
  <Modal>
    <h1>LMS toevoegen</h1>
    <form onSubmit={createLms}>
      <input className="input" placeholder="LMS naam" type="text" name="createLmsName" onChange={(e) => onChange(e.target.value)} />
      <div className="button_div">
        <button className="modal_button_secondary" type="button" onClick={() => setShowCreateCourseModal(false)}>Annuleren</button>
        <button className="modal_button_primary" type="submit">Verstuur</button>
      </div>
    </form>
  </Modal>
);
