import React, {
  FormEvent, useState,
} from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ApiController } from 'apiServices/apiController';
import { CourseGroupListModel, CourseGroupModel } from 'apiServices/controllers/courseGroup';
import Toast from 'shared/components/toast/toast';
import { Datagrid } from 'shared/components/datagrid/datagrid';
import classes from './course-groups-overview.module.scss';
import { CreateCourseGroupModal } from './components/create-coursegroup-modal';
import { CourseGroupsOverviewActions } from './components/course-groups-overview-actions';
import { EditCourseGroupModal } from './components/edit-coursegroup-modal';
import { DeleteCourseGroupModal } from './components/delete-coursegroup-modal';

export type CourseGroupTableItem = {
  id: number;
  urlId: string;
  recordName: string | null;
  actions: JSX.Element;
};

type CourseGroupsOverviewProps = {
  dataObject: CourseGroupListModel;
};

export const CourseGroupsOverview = ({ dataObject }: CourseGroupsOverviewProps) => {
  const [data, setData] = useState<CourseGroupModel[]>(dataObject.courseGroups ?? []);
  const [showCreateCourseModal, setShowCreateCourseModal] = useState(false);
  const [showEditCourseModal, setShowEditCourseModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [toastData, setToastData] = useState({
    showToast: false,
    toastText: '',
  });
  const [createCourseName, setCreateCourseName] = useState('');
  const [newCourseName, setNewCourseName] = useState('');
  const [objectData, setObjectData] = useState({
    objectKey: 0,
    objectId: '',
    objectTextName: '',
  });

  // edit
  const onEdit = (item: CourseGroupModel, key: number) => {
    setObjectData({
      ...objectData, objectKey: key, objectId: item.id, objectTextName: item.name || '',
    });
    setShowEditCourseModal(true);
    setNewCourseName('');
  };

  const editCourseGroup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await ApiController.CourseGroup.EditCourseGroup(objectData.objectId, newCourseName);
      const update = [...data];
      update[data.map((d) => d.id).indexOf(objectData.objectId)].name = newCourseName;

      setShowEditCourseModal(false);
      setData(update);
    } catch (error) {
      return setToastData({ toastText: 'De cursus groep kon niet aangepast worden', showToast: true });
    }
  };

  // delete
  const onDelete = (item: CourseGroupModel, key: number) => {
    setObjectData({
      ...objectData, objectKey: key, objectId: item.id, objectTextName: item.name || '',
    });
    setShowDeleteConfirmModal(true);
  };

  const deleteCourseGroup = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      await ApiController.CourseGroup.deleteCourseGroup(objectData.objectId);
      const update = data.filter((item: CourseGroupModel) => item.id !== objectData.objectId);

      setData(update);
      setShowDeleteConfirmModal(false);
    } catch (error) {
      setToastData({ toastText: 'Kon cursus groep niet verwijderen', showToast: true });
    }
  };

  // create
  const createCourseGroup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const newCourse = await ApiController.CourseGroup.createCourseGroup(createCourseName);

      setData((prev) => [...prev, newCourse]);
      setShowCreateCourseModal(false);
      setCreateCourseName('');
    } catch (error) {
      setToastData({ toastText: 'Er kon geen cursusgroep aangemaakt worden', showToast: true });
    }
  };

  const tableData = data.map((item: CourseGroupModel, key) => ({
    id: key,
    urlId: item.id,
    recordName: item.name,
    actions: <CourseGroupsOverviewActions item={item} key={key} onEdit={onEdit} onDelete={onDelete} />,
  }));

  return (
    <>
      {showCreateCourseModal && <CreateCourseGroupModal createCourseGroup={createCourseGroup} value={createCourseName} onChange={setCreateCourseName} setShowCreateCourseModal={setShowCreateCourseModal} />}
      {showEditCourseModal && <EditCourseGroupModal editCourseGroup={editCourseGroup} value={newCourseName} onChange={setNewCourseName} setShowEditCourseModal={setShowEditCourseModal} />}
      {showDeleteConfirmModal && <DeleteCourseGroupModal setShowDeleteConfirmModal={setShowDeleteConfirmModal} deleteCourseGroup={deleteCourseGroup} />}
      <Toast text={toastData.toastText} showToast={toastData.showToast} setShowToast={setToastData} />
      <div className={classes.course_groups_overview}>
        <div className={classes.header}>
          <h1>Cursus groepen</h1>
          <AddCircleIcon className={classes.add_icon} onClick={() => setShowCreateCourseModal(true)} />
        </div>
        <div className={classes.datagrid}>
          <Datagrid dataTable={tableData ?? []} urlPath="cursus-groep" />
        </div>
      </div>
    </>
  );
};
