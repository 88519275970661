import React, { useEffect, useRef, useCallback } from "react";
import { AuthController } from "../apiServices/controllers/auth";
import { logoutAction } from "./actions/logout";
import { setAuthenticationTokensAction } from "./actions/setAuthenticationTokensAction";
import { AppState, updateAppState, useAppState } from "./appState";
import { TokenStatus } from "./config/token";

enum TimerActions {
  Reset = "Reset",
}

const selector = (state: AppState) => ({
  authentication: state.authentication,
  isAuthenticated: state.isAuthenticated,
});

const setLoading = (val: boolean) => {
  updateAppState((state) => {
    state.authentication.loading = val;
  });
};

const TokenManager = () => {
  const timerRef = useRef(null as NodeJS.Timer | null);
  const state = useAppState(selector);
  const { authentication } = state.current;

  const setRefreshTokenTimer = useCallback(
    async (time: number) =>
      new Promise<void>((resolve, reject) => {
        if (timerRef.current) clearTimeout(timerRef.current);

        timerRef.current = setTimeout(async () => {
          if (!authentication)
            throw Error(
              "There is possibly a bug in the code with the authentication"
            );
          if (!authentication.refreshToken)
            throw Error("no RefreshToken found");

          try {
            const res = await AuthController.getNewAccessToken(
              authentication.refreshToken.raw
            );
            const newTokens = res || null;

            if (
              typeof newTokens?.accessToken?.token === "string" &&
              typeof newTokens?.refreshToken?.token === "string"
            ) {
              setAuthenticationTokensAction({
                accessToken: newTokens.accessToken.token,
                refreshToken: newTokens.refreshToken.token,
              });
              resolve();
            }

            reject(new Error("invalidToken"));
          } catch (e) {
            reject(e);
          }
        }, time);
      }),
    [authentication]
  );

  const setTokenValidator = useCallback(async () => {
    if (!authentication)
      throw Error(
        "There is possibly a bug in the code with the authentication"
      );
    if (!authentication.accessToken) throw Error("no AccessToken found");
    if (!authentication.accessToken.refreshAt)
      throw Error("no refreshTimerFound");
    if (!authentication.accessToken.expiresAt) throw Error("no expireAtFound");
    if (authentication.accessToken.status === TokenStatus.Valid) {
      setLoading(false);
    }

    const timeLeft = authentication.accessToken.refreshAt - Date.now();

    try {
      await setRefreshTokenTimer(timeLeft);
    } catch (error) {
      if (error.message === TimerActions.Reset) {
        logoutAction();
        return;
      }

      const retryTime = (authentication.accessToken.expiresAt - Date.now()) / 2;
      try {
        await setRefreshTokenTimer(retryTime);
      } catch (innerError) {
        logoutAction();
      }
    }
  }, [authentication, setRefreshTokenTimer]);

  useEffect(() => {
    if (
      !authentication ||
      !authentication.accessToken ||
      !authentication.refreshToken
    ) {
      setLoading(false);
      return;
    }

    setTokenValidator();
  }, [setTokenValidator, authentication]);

  return <></>;
};

export { TokenManager };
