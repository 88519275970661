import { LmsController } from './controllers/lms';
import { CourseController } from './controllers/course';
import { CourseGroupController } from './controllers/courseGroup';

export class ApiController {
  constructor() {
    throw Error(
      'APIcontroller should not initialize, try using it like this => ApiController.Auth'
    );
  }

  static CourseGroup = CourseGroupController;

  static Course = CourseController;

  static Lms = LmsController;

}
