import React, {
  ChangeEvent, FormEvent, useState,
} from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { CourseResultModel } from 'apiServices/controllers/course';
import { Datagrid } from 'shared/components/datagrid/datagrid';
import { CoursesByCourseGroupModel } from 'apiServices/controllers/courseGroup';
import { ApiController } from 'apiServices/apiController';
import Toast from 'shared/components/toast/toast';
import classes from './course-group-overview.module.scss';
import { CreateCourseModal } from './components/create-course-modal';
import { EditCourseModal } from './components/edit-course-modal';
import { DeleteCourseModal } from './components/delete-course-modal';
import { CourseGroupOverviewActions } from './components/course-group-overview-actions';

type CourseGroupOverviewProps = {
  dataObject: CoursesByCourseGroupModel
};

type FormDataType = {
  newVersion: string | undefined;
  newEntryPoint: string | undefined;
  newScormVersion: string | undefined;
};

export const CourseGroupOverview = ({ dataObject }: CourseGroupOverviewProps) => {
  const [data, setData] = useState<CourseResultModel[]>(dataObject.courses);
  const [showCreateCourseModal, setShowCreateCourseModal] = useState(false);
  const [showEditCourseModal, setShowEditCourseModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [toastData, setToastData] = useState({
    showToast: false,
    toastText: '',
  });
  const [createCourseName, setCreateCourseName] = useState('');
  const [formData, setFormData] = useState<FormDataType>({
    newVersion: '',
    newEntryPoint: '',
    newScormVersion: '',
  });
  const [objectData, setObjectData] = useState({
    objectKey: 0,
    objectId: '',
    objectTextName: '',
  });

  // form
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // edit
  const onEdit = (item: CourseResultModel, key: number) => {
    setObjectData({ objectKey: key, objectId: item.id, objectTextName: item.version || '' });

    setFormData((prev) => ({
      ...prev, newVersion: item.version, newEntryPoint: item.entryPoint, newScormVersion: item.scormVersion,
    }));
    setShowEditCourseModal(true);
  };

  const editCourseGroup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await ApiController.Course.EditCourse(objectData.objectId, formData.newVersion, formData.newEntryPoint, formData.newScormVersion);

      const update = [...data];
      const selectedIndex = data.map((d) => d.id).indexOf(objectData.objectId);

      const updatedEntry = {
        ...update[selectedIndex], version: formData.newVersion, entryPoint: formData.newEntryPoint, scormVersion: formData.newScormVersion,
      };

      update[selectedIndex] = updatedEntry;

      setShowEditCourseModal(false);
      setData(update);
    } catch (error) {
      return setToastData({ toastText: 'De cursus kon niet aangepast worden', showToast: true });
    }
  };

  // delete
  const onDelete = (item: CourseResultModel, key: number) => {
    setObjectData({ objectKey: key, objectId: item.id, objectTextName: item.version || '' });
    setShowDeleteConfirmModal(true);
  };

  const deleteCourseGroup = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      const response = await ApiController.Course.deleteCourse(objectData.objectId);

      if (response) {
        const newData = JSON.parse(JSON.stringify(data));
        const filteredData = newData.filter((item: CourseResultModel) => item.id !== objectData.objectId);

        setData(filteredData);
        setShowDeleteConfirmModal(false);
      }
    } catch (error) {
      setToastData({ toastText: 'Kon cursus niet verwijderen', showToast: true });
    }
  };

  // create
  const createCourseGroup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const newCourse = await ApiController.Course.createCourse(createCourseName, dataObject.id);

      if (newCourse) {
        setData((prev) => ([...prev, newCourse]));
        setShowCreateCourseModal(false);
        setCreateCourseName('');
      }
    } catch (error) {
      setToastData({ toastText: 'Er kon geen cursus aangemaakt worden', showToast: true });
    }
  };

  const tableData = data.map((item: CourseResultModel, key) => ({
    id: key,
    urlId: item.id,
    recordName: item.version || null,
    actions: <CourseGroupOverviewActions item={item} key={key} onEdit={onEdit} onDelete={onDelete} />,
  }));

  return (
    <>
      {showCreateCourseModal && <CreateCourseModal createCourseGroup={createCourseGroup} onChange={setCreateCourseName} setShowCreateCourseModal={setShowCreateCourseModal} />}
      {showEditCourseModal && <EditCourseModal editCourseGroup={editCourseGroup} version={formData.newVersion} entryPoint={formData.newEntryPoint} scormVersion={formData.newScormVersion} onChange={onChange} setShowEditCourseModal={setShowEditCourseModal} />}
      {showDeleteConfirmModal && <DeleteCourseModal setShowDeleteConfirmModal={setShowDeleteConfirmModal} deleteCourseGroup={deleteCourseGroup} />}
      <Toast text={toastData.toastText} showToast={toastData.showToast} setShowToast={setToastData} />
      <div className={classes.course_groups_overview}>
        <div className={classes.header}>
          <h1>Cursus groep: {dataObject.name}</h1>
          <AddCircleIcon className={classes.add_icon} onClick={() => setShowCreateCourseModal(true)} />
        </div>
        <div className={classes.datagrid}>
          <Datagrid dataTable={tableData} urlPath="cursus" />
        </div>
      </div>
    </>
  );
};
