import React from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { CourseResultModel } from 'apiServices/controllers/course';
import classes from '../lms-detail.module.scss';
import { LmsDetailAjaxFetchData } from '../lms-detail-ajax';

interface LmsDetailActionsProps {
  data: LmsDetailAjaxFetchData;
  item: CourseResultModel;
  onEdit: (id: string, add: boolean) => Promise<void>;
}

export const LmsDetailActions = ({
  onEdit, item, data,
}: LmsDetailActionsProps) => (
  <div style={{ display: 'flex', marginLeft: '1em' }} className="actions-right">
    {data.lmsData?.lmsCourses?.find((course) => course.courseId === item.id) ? < button
      className={classes.button}
      type='button'
      onClick={(e) => {
        onEdit(item.id, false);
        e.preventDefault();
      }}>
      <CheckBoxIcon />
    </button>
      : < button
        className={classes.button}
        type='button'
        onClick={(e) => {
          onEdit(item.id, true);
          e.preventDefault();
        }}>
        <CheckBoxOutlineBlankIcon />
      </button >
    }
  </div >
);
