/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import 'mui-pro/assets/scss/material-dashboard-pro-react.scss?v=1.9.0';
import { App } from 'app';
import './styles/main.scss';
import initInterceptor from 'auth/config/fetch-interceptor';

initInterceptor();

ReactDOM.render(<App />, document.getElementById('root'));
