import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiController } from 'apiServices/apiController';
import { LmsResultModel } from 'apiServices/controllers/lms';
import { CourseResultModel } from 'apiServices/controllers/course';
import { LmsDetail } from './lms-detail';

export type LmsDetailAjaxFetchData = {
  lmsData: LmsResultModel | null;
  allCourses: CourseResultModel[] | null;
  token: string | null;
};

type LmsDetailAjaxFetch = {
  isFetched: boolean;
  loading: boolean;
  error: boolean;
  data: LmsDetailAjaxFetchData | null;
};

export const LmsDetailAjax = () => {
  const { id }: any = useParams();
  const [{ isFetched, loading, error, data }, setLmsAjaxFetch] = useState<LmsDetailAjaxFetch>({
    isFetched: false,
    loading: true,
    error: false,
    data: null,
  });

  useEffect(() => {
    if (!isFetched) {
      setLmsAjaxFetch((prev) => ({ ...prev, isFetched: true }));

      try {
        const fetchLmsList = async () => {
          const [lmsData, allCourses] = await Promise.all([
            ApiController.Lms.getLmsById(id),
            ApiController.Course.getAllCourses(),
          ]);

          const { token } = await ApiController.Lms.getTokenByLmsId(lmsData.id);

          return setLmsAjaxFetch({
            isFetched: true,
            loading: false,
            error: false,
            data: { lmsData, allCourses: allCourses.courses, token },
          });
        };

        fetchLmsList();
      } catch (error) {
        setLmsAjaxFetch({ isFetched: true, loading: false, error: true, data: null });
      }
    }
  }, [isFetched, id]);

  if (!data || loading) {
    return <>Laden...</>;
  }
  if (error) {
    return <>Kon lms / lms token niet ophalen</>;
  }

  return <LmsDetail dataObject={data} />;
};
