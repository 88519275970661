import React, {
  ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import { ApiController } from 'apiServices/apiController';
import { CourseContentStatusEnum, CourseResultModel, LmsCourseResultModel } from 'apiServices/controllers/course';
import { Edit, HighlightOff } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Toast from 'shared/components/toast/toast';
import { Datagrid } from 'shared/components/datagrid/datagrid';
import classes from './course-overview.module.scss';
import { EditCourseModal } from './components/edit-course-modal';
import { DeleteCourseModal } from './components/delete-course-modal';

type CourseOverviewProps = {
  dataObject: CourseResultModel;
};

interface UploadStatusInterface {
  text: string;
  color: string;
}

export const CourseOverview = ({ dataObject }: CourseOverviewProps) => {
  const history = useHistory();
  const [data, setData] = useState<CourseResultModel>(dataObject);
  const [showEditCourseModal, setShowEditCourseModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const hasSetUploadedContentStatus = useRef(false);
  const [uploading, setUploading] = useState(false);
  const [uploadedContentStatus, setUploadedContentStatus] = useState<UploadStatusInterface>({
    color: '',
    text: '',
  });
  const [uploadedFile, setUploadedFile] = useState('');
  const [toastData, setToastData] = useState({
    showToast: false,
    toastText: '',
  });
  const [formData, setFormData] = useState({
    newVersion: data.version || undefined,
    newEntryPoint: data.entryPoint || undefined,
    newScormVersion: data.scormVersion || undefined,
  });

  // form
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // edit
  const editCourse = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await ApiController.Course.EditCourse(data.id, formData.newVersion, formData.newEntryPoint, formData.newScormVersion);

      setData((prev) => ({
        ...prev, version: formData.newVersion, entryPoint: formData.newEntryPoint, scormVersion: formData.newScormVersion,
      }));
      setShowEditCourseModal(false);
    } catch (error) {
      return setToastData({ toastText: 'De cursus kon niet aangepast worden', showToast: true });
    }
  };

  // delete
  const deleteCourse = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      await ApiController.Course.deleteCourse(data.id);

      setShowDeleteConfirmModal(false);
      history.goBack();
    } catch (error) {
      setToastData({ toastText: 'Kon cursus niet verwijderen', showToast: true });
    }
  };

  // add zip
  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let blob: Blob | null = null;
    setUploadedFile(e?.target?.files?.[0]?.name ?? 'Bestand uploaden is niet gelukt');
    setUploading(true);

    try {
      if (e.target.files) {
        blob = new Blob([e.target.files && e.target.files[0]]);
        await ApiController.Course.uploadZip(data.id, blob);
        setUploading(false);
        setUploadedContentStatus({
          text: 'Bestand wordt geverifieerd ververs de pagina om de huidige status te zien',
          color: 'orange',
        });
      }
    } catch (error) {
      setUploading(false);
      setToastData({ toastText: 'Kon cursus zip niet uploaden', showToast: true });
    }
  };

  const setFileStatus = useCallback(() => {
    let text = '';
    let color = '';

    switch (data.contentStatus) {
      case CourseContentStatusEnum.Deleted:
        text = 'Bestand is verwijderd';
        color = 'black';
        break;
      case CourseContentStatusEnum.NoContent:
        text = 'Geen bestand geüpload';
        color = 'black';
        break;
      case CourseContentStatusEnum.Unknown:
        text = 'Status van bestand is onbekend';
        color = 'red';
        break;
      case CourseContentStatusEnum.UploadFailed:
        text = 'Betand is niet succesvol geüpload';
        color = 'red';
        break;
      case CourseContentStatusEnum.UploadQueued:
        text = 'Bestand staat in de wachtrij';
        color = 'orange';
        break;
      case CourseContentStatusEnum.UploadSucceeded:
        text = 'Bestand succesvol geüpload';
        color = 'green';
        break;
      case CourseContentStatusEnum.Uploading:
        text = 'Bestand wordt geüpload';
        color = 'orange';
        break;
      case CourseContentStatusEnum.VerificationFailed:
        text = 'Verificatie voor bestand niet succesvol';
        color = 'red';
        break;
      case CourseContentStatusEnum.Verifying:
        text = 'Bestand wordt geverifieerd';
        color = 'orange';
        break;
      default:
        text = 'Bestand status onbekend';
        color = 'red';
        break;
    }
    hasSetUploadedContentStatus.current = true;

    setUploadedContentStatus({
      text,
      color,
    });
  }, [data.contentStatus]);

  useEffect(() => {
    if (!hasSetUploadedContentStatus.current) {
      setFileStatus();
    }
  }, [setFileStatus]);

  const tableData = (data.lmsCourses ?? []).map((item: LmsCourseResultModel, key) => ({
    id: key,
    urlId: item.lmsId,
    recordName: item.lmsName,
    actions: null,
  }));

  return (
    <>
      {showEditCourseModal && <EditCourseModal editCourse={editCourse} version={formData.newVersion} entryPoint={formData.newEntryPoint} scormVersion={formData.newScormVersion} onChange={onChange} setShowEditCourseModal={setShowEditCourseModal} />}
      {showDeleteConfirmModal && <DeleteCourseModal setShowDeleteConfirmModal={setShowDeleteConfirmModal} deleteCourse={deleteCourse} />}
      <Toast text={toastData.toastText} showToast={toastData.showToast} setShowToast={setToastData} />
      <div className={classes.course_groups_overview}>
        <div className={classes.header}>
          <h1>{data.courseGroupName || 'Cursus'}: {data.version}</h1>
          <div className={classes.actions}>
            <Edit className={classes.edit_icon} onClick={() => setShowEditCourseModal(true)} />
            <HighlightOff className={classes.delete_icon} onClick={() => setShowDeleteConfirmModal(true)} />
          </div>
        </div>
        <div>
          <p className={classes.data}><b>SCORM:</b> {data.scormVersion || ' NB'}</p>
          <p className={classes.data}><b>Entrypoint:</b> {data.entryPoint || ' NB'}</p>
          <input className={classes.file_input} id="files" type="file" accept=".zip" onChange={(e) => onFileChange(e)} placeholder="Selecteer een bestand" />
          <div className={classes.upload_conatiner}>
            <button disabled={uploading} className={classes.upload_button}>
              {uploading ? <label>Bestand word geüpload</label> : <label htmlFor="files">Selecteer een bestand</label> }
            </button>
            <span>{uploadedFile}</span>
          </div>
          <div className={classes.upload_status}>
            {uploading
              ? <div className={classes.lds_spinner}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              : <span style={{ color: uploadedContentStatus.color }}>{uploadedContentStatus.text}</span>
            }
            </div>
        </div>
        <div className={classes.datagrid}>
          <h2 className={classes.datagrid_header}>Gekoppelde LMS'en</h2>
          <Datagrid dataTable={tableData} />
        </div>
      </div>
    </>
  );
};
