import React, {
  FormEvent, useState,
} from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ApiController } from 'apiServices/apiController';
import { LmsListModel, LmsResultModel } from 'apiServices/controllers/lms';
import Toast from 'shared/components/toast/toast';
import { Datagrid } from 'shared/components/datagrid/datagrid';
import classes from './lms-overview.module.scss';
import { CreateLmsModal } from './components/create-lms-modal';
import { LmsOverviewActions } from './components/lms-overview-actions';
import { EditLmsModal } from './components/edit-lms-modal';
import { DeleteLmsModal } from './components/delete-lms-modal';

type LmsOverviewProps = {
  dataObject: LmsListModel;
};

export const LmsOverview = ({ dataObject }: LmsOverviewProps) => {
  const [data, setData] = useState<LmsListModel>(dataObject ?? []);
  const [showCreateCourseModal, setShowCreateCourseModal] = useState(false);
  const [showEditCourseModal, setShowEditCourseModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [toastData, setToastData] = useState({
    showToast: false,
    toastText: '',
  });
  const [createLmsName, setCreateLmsName] = useState('');
  const [newLmsName, setNewLmsName] = useState('');
  const [objectData, setObjectData] = useState({
    objectKey: 0,
    objectId: '',
    objectTextName: '',
  });

  // edit
  const onEdit = (item: LmsResultModel, key: number) => {
    setObjectData({
      ...objectData, objectKey: key, objectId: item.id, objectTextName: item.name || '',
    });
    setShowEditCourseModal(true);
    setNewLmsName('');
  };

  const editLms = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await ApiController.Lms.EditLms(objectData.objectId, newLmsName);
      const update = data.lmses && data.lmses.map((d) => {
        if (d.id === objectData.objectId) {
          return {
            ...d,
            name: newLmsName,
          };
        }
        return d;
      });

      setShowEditCourseModal(false);
      setData((prev) => ({ ...prev, lmses: update }));
    } catch (error) {
      return setToastData({ toastText: 'De lms kon niet aangepast worden', showToast: true });
    }
  };

  // delete
  const onDelete = (item: LmsResultModel, key: number) => {
    setObjectData({
      ...objectData, objectKey: key, objectId: item.id, objectTextName: item.name || '',
    });
    setShowDeleteConfirmModal(true);
  };

  const deleteLms = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      await ApiController.Lms.deleteLms(objectData.objectId);
      const update = data?.lmses?.filter((lms) => lms.id !== objectData.objectId) ?? null;

      setData((prev) => ({ ...prev, lmses: update }));
      setShowDeleteConfirmModal(false);
    } catch (error) {
      setToastData({ toastText: 'Kon lms niet verwijderen', showToast: true });
    }
  };

  // create
  const createLms = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const newLms = await ApiController.Lms.createLms(createLmsName);

      const update = { ...data };
      update.lmses?.push(newLms);
      setData(update);
      setShowCreateCourseModal(false);
      setCreateLmsName('');
    } catch (error) {
      setToastData({ toastText: 'Er kon geen LMS aangemaakt worden', showToast: true });
    }
  };

  const tableData = data.lmses && data.lmses.map((item: LmsResultModel, key) => ({
    id: key,
    urlId: item.id,
    recordName: item.name,
    actions: <LmsOverviewActions item={item} key={key} onEdit={onEdit} onDelete={onDelete} />,
  }));

  return (
    <>
      {showCreateCourseModal && <CreateLmsModal createLms={createLms} onChange={setCreateLmsName} setShowCreateCourseModal={setShowCreateCourseModal} />}
      {showEditCourseModal && <EditLmsModal editLms={editLms} onChange={setNewLmsName} setShowEditCourseModal={setShowEditCourseModal} />}
      {showDeleteConfirmModal && <DeleteLmsModal setShowDeleteConfirmModal={setShowDeleteConfirmModal} deleteLms={deleteLms} />}
      <Toast text={toastData.toastText} showToast={toastData.showToast} setShowToast={setToastData} />
      <div className={classes.lms_overview}>
        <div className={classes.header}>
          <h1>LMS Overzicht</h1>
          <AddCircleIcon className={classes.add_icon} onClick={() => setShowCreateCourseModal(true)} />
        </div>
        <div className={classes.datagrid}>
          <Datagrid dataTable={tableData ?? []} urlPath="lms" />
        </div>
      </div>
    </>
  );
};
