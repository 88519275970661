export const http = <T>(
  request: RequestInfo,
  init?: RequestInit | undefined,
): Promise<T> => fetch(request, init)
    .then(async (response) => {
      const responseClone = response.clone();
      try {
        await responseClone.json();
        return response.json();
      } catch (error) {
        return {};
      }
    })
    .catch((error) => {
      console.warn(`something went wrong error: ${error}`);
      throw new Error(error);
    });
