import { http } from '../http';

export enum CourseContentStatusEnum {
  Unknown = 'Unknown',
  NoContent = 'NoContent',
  UploadQueued = 'UploadQueued',
  Verifying = 'Verifying',
  VerificationFailed = 'VerificationFailed',
  Uploading = 'Uploading',
  UploadSucceeded = 'UploadSucceeded',
  UploadFailed = 'UploadFailed',
  Deleted = 'Deleted'
}

export type CourseResultModel = {
  id: string;
  version?: string;
  courseGroupId: string;
  courseGroupName: string | null;
  entryPoint?: string;
  scormVersion?: string;
  contentStatus: CourseContentStatusEnum;
  lmsCourses: LmsCourseResultModel[] | null;
  isValid: boolean;
  message: string | null;
};

export type LmsCourseResultModel = {
  lmsId: string;
  lmsName: string | null;
  courseId: string;
  isValid: boolean;
  message: string | null;
};

export type CourseListModel = {
  courses: CourseResultModel[] | null;
  isValid: boolean;
  message: string;
};

export class CourseController {
  constructor() {
    throw Error(
      'CourseController should not initialize, try using it like this => CourseController.login',
    );
  }

  static getAllCourses = async (): Promise<CourseListModel> => http<CourseListModel>(`${process.env.REACT_APP_SERVER_URL}course/list`);

  static getCourseById = async (courseId: string): Promise<CourseResultModel> => http<CourseResultModel>(
    `${process.env.REACT_APP_SERVER_URL}course/${courseId}`,
  );

  static createCourse = async (version: string, courseGroupId: string) => http<Promise<CourseResultModel>>(
    `${process.env.REACT_APP_SERVER_URL}course/create`,
    {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({
        version,
        courseGroupId,
      }),
    },
  );

  static EditCourse = async (
    id: string,
    version?: string,
    entryPoint?: string,
    scormVersion?: string,
  ) => http<Promise<void | string>>(
    `${process.env.REACT_APP_SERVER_URL}course/update`,
    {
      method: 'PUT',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({
        id,
        version,
        entryPoint,
        scormVersion,
      }),
    },
  );

  static deleteCourse = async (courseId: string) => http<Promise<void | string>>(
    `${process.env.REACT_APP_SERVER_URL}course/delete/${courseId}`,
    {
      method: 'DELETE',
    },
  );

  static uploadZip = async (courseId: string, file: Blob) => http<Promise<void | string>>(
    `${process.env.REACT_APP_SERVER_URL}course/upload/${courseId}`,
    {
      method: 'POST',
      headers: { 'content-type': 'application/zip' },
      body: file,
    },
  );
}
