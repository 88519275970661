import { CourseResultModel } from './course';
import { http } from '../http';

export type CourseGroupListModel = {
  courseGroups: CourseGroupModel[] | null;
  isValid: boolean;
  message: string | null;
};

export type CourseGroupModel = {
  id: string;
  name: string | null;
  courses: CourseResultModel[] | null;
  isValid: boolean;
  message: string | null;
};

export type CoursesByCourseGroupModel = {
  id: string;
  name: string;
  courses: CourseResultModel[];
  isValid: boolean;
  message: string;
};

export type CourseGroupModelKey = keyof CourseGroupModel;

export class CourseGroupController {
  constructor() {
    throw Error(
      'CreditController should not initialize, try using it like this => CreditController.login',
    );
  }

  static getCourseGroupList = async (): Promise<CourseGroupListModel> => http<CourseGroupListModel>(`${process.env.REACT_APP_SERVER_URL}courseGroup/list`);

  static getCoursesByCourseGroup = async (courseGroupId: string): Promise<CoursesByCourseGroupModel> => http<CoursesByCourseGroupModel>(`${process.env.REACT_APP_SERVER_URL}courseGroup/${courseGroupId}`);

  static createCourseGroup = async (name: string) => http<Promise<CourseGroupModel>>(`${process.env.REACT_APP_SERVER_URL}courseGroup/create`, {
    method: 'POST',
    headers: new Headers(
      { 'content-type': 'application/json' },
    ),
    body: JSON.stringify({
      name,
    }),
  });

  static EditCourseGroup = async (id: string, name: string) => http<Promise<void | string>>(`${process.env.REACT_APP_SERVER_URL}courseGroup/update`, {
    method: 'PUT',
    headers: new Headers(
      { 'content-type': 'application/json' },
    ),
    body: JSON.stringify({
      id,
      name,
    }),
  });

  static deleteCourseGroup = async (name: string) => http<Promise<void | string>>(`${process.env.REACT_APP_SERVER_URL}courseGroup/delete/${name}`, {
    method: 'DELETE',
  });
}
