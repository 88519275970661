import React from 'react';
import { TokenManager } from 'auth/tokenManager';
import { LivisBackofficeApp } from 'livis-backoffice-app/livis-backoffice-app';

export const App = () => (
  <>
    <TokenManager />
    <LivisBackofficeApp />
  </>
);
