import React, { FormEvent } from 'react';
import { Modal } from 'shared/components/inputModal/modal';


type CreateCourseModalProps = {
  createCourseGroup: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  setShowCreateCourseModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateCourseGroupModal = ({
  createCourseGroup, value, onChange, setShowCreateCourseModal,
}: CreateCourseModalProps) => (
  <Modal>
    <h1>Cursus groep toevoegen</h1>
    <form onSubmit={createCourseGroup}>
      <input className="input" placeholder="Cursus naam" type="text" name="createCourseName" value={value} onChange={(e) => onChange(e.target.value)} />
      <div className="button_div">
        <button className="modal_button_secondary" type="button" onClick={() => setShowCreateCourseModal(false)}>Annuleren</button>
        <button className="modal_button_primary" type="submit">Verstuur</button>
      </div>
    </form>
  </Modal>
);
