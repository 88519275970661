import React from 'react';
import classes from './modal.module.scss';

type ModalProps = {
  children: React.ReactNode;
};

export const Modal = ({ children }: ModalProps) => (
        <>
            <div className={classes.overlay}>
                <div className={classes.modal}>
                    {children}
                </div>
            </div>
        </>
);
