import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Email from '@material-ui/icons/Email';
import GridContainer from 'mui-pro/components/Grid/GridContainer';
import GridItem from 'mui-pro/components/Grid/GridItem';
import CustomInput from 'mui-pro/components/CustomInput/CustomInput';
import Card from 'mui-pro/components/Card/Card';
import CardBody from 'mui-pro/components/Card/CardBody';
import CardHeader from 'mui-pro/components/Card/CardHeader';
import CardFooter from 'mui-pro/components/Card/CardFooter';

import styles from 'mui-pro/assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import { AuthController } from 'apiServices/controllers/auth';
import { loginAction } from 'auth/actions/login';
import { useHistory } from 'react-router-dom';
import customClasses from './login.module.scss';

const useStyles = makeStyles(styles as any);

export default function Login() {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const login = async (): Promise<void> => {
    try {
      const response = await AuthController.login(username, password);
      loginAction({ accessToken: response.accessToken.token, refreshToken: response.refreshToken.token });
      history.push('/');
    } catch {
      setError(true);
    }
  };

  const onChangeEvent = (setter: Dispatch<SetStateAction<string>>) => (
    e: SyntheticEvent<HTMLInputElement, ChangeEvent>,
  ): void => {
    setter(e.currentTarget.value);
  };

  const classes = useStyles();
  return (
    <div style={{ marginTop: '5em' }} className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: onChangeEvent(setUsername),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText="Wachtwoord"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: onChangeEvent(setPassword),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: 'password',
                    autoComplete: 'off',
                  }}
                />
                {error
                  && <p>Kon niet inloggen. Probeer het later opnieuw.</p>}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <button
                  onClick={login}
                  className={customClasses.button}
                  type="button"
                >
                  Inloggen
                </button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
