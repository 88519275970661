export const layout = {
  public: '/',
  admin: '/admin/',
};

export const routes = {
  login: 'login',
  courseGroups: 'cursus-groepen',
  courseGroup: 'cursus-groep/:id',
  course: 'cursus/:id',
  lmsOverview: 'lms-overview',
  lms: 'lms/:id',
};

export enum MenuNames {
  courseGroups = 'Cursus groepen',
  LMS = 'LMS'
}
