import React, {
  useState,
} from 'react';
import { CourseResultModel, LmsCourseResultModel } from 'apiServices/controllers/course';
import { ApiController } from 'apiServices/apiController';
import Toast from 'shared/components/toast/toast';
import { Datagrid } from 'shared/components/datagrid/datagrid';
import classes from './lms-detail.module.scss';
import { LmsDetailAjaxFetchData } from './lms-detail-ajax';
import { LmsDetailActions } from './components/lms-detail-actions';

type LmsDetailProps = {
  dataObject: LmsDetailAjaxFetchData;
};

export const LmsDetail = ({ dataObject }: LmsDetailProps) => {
  const [data, setData] = useState<LmsDetailAjaxFetchData>(dataObject ?? {});
  const [toastData, setToastData] = useState({
    showToast: false,
    toastText: '',
  });

  // edit
  const onEdit = async (id: string, add: boolean) => {
    let coupledCourses: string[] | undefined;

    if (add) {
      coupledCourses = data.lmsData?.lmsCourses?.map((course) => course.courseId);
      coupledCourses && coupledCourses.push(id);
    } else {
      const copyCourses = data.lmsData?.lmsCourses?.map((course) => course.courseId);
      coupledCourses = copyCourses && copyCourses.filter((course) => course !== id);
    }

    try {
      await ApiController.Lms.coupleLmsToCourses(data.lmsData?.id ?? '', coupledCourses);
      const b = data.allCourses ? data.allCourses.filter((course) => coupledCourses?.some((c) => c === course.id)) : [];
      setData((prev) => ({
        ...prev,
        lmsData: {
          ...prev.lmsData,
          id: data.lmsData?.id ?? '',
          isValid: true,
          message: '',
          name: '',
          lmsCourses: b.map((c): LmsCourseResultModel => ({
            ...c, lmsId: c.id, lmsName: '', courseId: c.id,
          })),
        },
      }));
    } catch (error) {
      return setToastData({ toastText: 'De cursus kon niet (af)gekoppeld worden', showToast: true });
    }
  };

  const tableData = data.allCourses && data.allCourses.map((item: CourseResultModel, key) => ({
    id: key,
    recordName: `${item.courseGroupName} - ${item.version}`,
    checked: !!data.lmsData?.lmsCourses?.find((course) => course.courseId === item.id),
    actions: <LmsDetailActions item={item} onEdit={onEdit} data={data} />,
  })).sort((a, b) => {
    if (b.checked > a.checked) return 1;
    return -1;
  });

  return (
    <>
      <Toast text={toastData.toastText} showToast={toastData.showToast} setShowToast={setToastData} />
      <div className={classes.lms_overview}>
        <h1>LMS: {data.lmsData?.name}</h1>
        <p>Token:</p>
        <p className={classes.token}>{data.token || 'NB'}</p>
        <div className={classes.datagrid}>
          <Datagrid dataTable={tableData ?? []} />
        </div>
      </div>
    </>
  );
};
