import { Snackbar } from '@material-ui/core';
import GridItem from 'mui-pro/components/Grid/GridItem';
import React, { useEffect } from 'react';

type ToastAction = {
  showToast: boolean;
  toastText: string;
};

type ToastProps = {
  text: string;
  showToast: boolean;
  setShowToast: React.Dispatch<React.SetStateAction<ToastAction>>
};

export default function Toast({
  text, showToast, setShowToast,
}: ToastProps) {
  useEffect(() => {
    setTimeout(() => {
      setShowToast({ toastText: '', showToast: false });
    }, 5000);
  }, [showToast, setShowToast]);

  return (
    <div onClick={() => setShowToast({ toastText: '', showToast: false })}>
      <GridItem xs={12} sm={12} md={3}>
        <Snackbar
          color="info"
          message={text}
          open={showToast}
        />
      </GridItem>
    </div>
  );
}
