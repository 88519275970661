import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Person from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';
import { CourseGroupsAjax } from 'views/course-groups-overview/course-groups-ajax';
import { CourseGroupAjax } from 'views/course-group-overview/course-group-ajax';
import { CourseOverviewAjax } from 'views/course-overview/course-overview-ajax';
import { LmsAjax } from 'views/lms-overview/lms-overview-ajax';
import { LmsDetailAjax } from '../views/lms-detail/lms-detail-ajax';
import Login from '../views/login/login';
import {
  routes, MenuNames, layout,
} from './route-variables';

export type RouteType = {
  path?: string;
  name: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  component?: any;
  layout: string;
  collapse?: boolean;
  mini?: string;
  state?: string;
  views?: RouteType[];
};

export const Routes = () => (
  <Switch>
    <Route
      exact path={`${layout.public}${routes.login}`}
      component={Login}
    />
    <Route
      exact path={`${layout.public}${routes.courseGroups}`}
      component={CourseGroupsAjax}
    />
    <Route
      exact path={`${layout.public}${routes.courseGroup}`}
      component={CourseGroupAjax}
    />
    <Route
      exact path={`${layout.public}${routes.course}`}
      component={CourseOverviewAjax}
    />
    <Route
      exact path={`${layout.public}${routes.lmsOverview}`}
      component={LmsAjax}
    />
    <Route
      exact path={`${layout.public}${routes.lms}`}
      component={LmsDetailAjax}
    />
    <Redirect from="/" to={`${layout.public}${routes.courseGroups}`} />
  </Switch>
);

export const dashRoutes: RouteType[] = [
  {
    path: `${routes.courseGroups}`,
    collapse: false,
    name: MenuNames.courseGroups,
    icon: BusinessIcon,
    state: 'OrganisationCollapse',
    layout: layout.public,
    views: [],
  },
  {
    path: `${routes.lmsOverview}`,
    collapse: false,
    name: MenuNames.LMS,
    icon: Person,
    state: 'ContributorsCollapse',
    layout: layout.public,
    views: [],
  },
];
