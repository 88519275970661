import { createImmutableState } from "../tools/immutable-state";
import Token, { TokenStatus } from "./config/token";

const tokens = {
  accessToken: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken"),
};

const accessToken = tokens.accessToken ? new Token(tokens.accessToken) : null;
const refreshToken = tokens.refreshToken
  ? new Token(tokens.refreshToken)
  : null;
const isAuthenticated =
  accessToken?.status === TokenStatus.Valid &&
  refreshToken?.status === TokenStatus.Valid;

const initialState = {
  isAuthenticated,
  authentication: {
    accessToken,
    refreshToken,
    sessionId: "",
    id: "",
    role: "",
    loading: true,
  },
  userDetails: {
    name: "",
    email: "",
    loading: true,
    id: "",
  },
};

export type AppState = typeof initialState;
export const {
  useImmutableState: useAppState,
  updateState: updateAppState,
} = createImmutableState("AppState", initialState);
