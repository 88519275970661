import { Modal } from 'shared/components/inputModal/modal';
import React, { FormEvent } from 'react';


type CreateCourseModalProps = {
  editCourseGroup: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  setShowEditCourseModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditCourseGroupModal = ({
  editCourseGroup, value, onChange, setShowEditCourseModal,
}: CreateCourseModalProps) => (
  <Modal>
    <h1>Cursus groep naam aanpassen</h1>
    <form onSubmit={editCourseGroup}>
      <input className="input" placeholder="Nieuwe cursus groep naam" type="text" name="newCourseName" value={value} onChange={(e) => onChange(e.target.value)} />
      <div className="button_div">
        <button className="modal_button_secondary" type="button" onClick={() => setShowEditCourseModal(false)}>Annuleren</button>
        <button className="modal_button_primary" type="submit">Verstuur</button>
      </div>
    </form>
  </Modal>
);
