// apptsx router
import React from 'react';
import {
    Router, Route, Switch,
} from 'react-router-dom';
import DashboardLayout from 'views/dashboard/dashboard';
import Login from 'views/login/login';
import { AuthenticatedRoute } from 'livis-backoffice-app/authenticatedRoute';
import { createBrowserHistory } from 'history';

const hist = createBrowserHistory();

export const LivisBackofficeApp = () => (
    <Router history={hist}>
        <Switch>
            <Route exact path="/login" component={Login} />
            <AuthenticatedRoute path="/" component={DashboardLayout} />
        </Switch>
    </Router>
);
