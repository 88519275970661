import { Modal } from 'shared/components/inputModal/modal';
import React, { FormEvent } from 'react';


type CreateCourseModalProps = {
  editLms: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  setShowEditCourseModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditLmsModal = ({
  editLms, onChange, setShowEditCourseModal,
}: CreateCourseModalProps) => (
  <Modal>
    <h1>LMS aanpassen</h1>
    <form onSubmit={editLms}>
      <input className="input" placeholder="Nieuwe cursus groep naam" type="text" name="newLmsName" onChange={(e) => onChange(e.target.value)} />
      <div className="button_div">
        <button className="modal_button_secondary" type="button" onClick={() => setShowEditCourseModal(false)}>Annuleren</button>
        <button className="modal_button_primary" type="submit">Verstuur</button>
      </div>
    </form>
  </Modal>
);
